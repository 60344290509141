import React from "react";

import Footer from "../component/Footer";
import MainPageTemplate from "../element/MainPageTemplate";
import { Link } from "react-router-dom";

const ProjectPage = () => {
  const textColor = "md:text-black sm:text-gray-500";

  return (
    <MainPageTemplate textColor={textColor}>
      <div className="mt-[7rem] font-libra ">
        <div className="flex items-center justify-center text-5xl font-semibold">
          PROJECTS
        </div>
        <div className="mt-16 mb-4 sm:p-4 lg:p-0 flex gap-10 flex-col">
          <div className="flex sm:flex-col lg:flex-row justify-evenly gap-4 items-center">
            <Link to="/vyom-apartment" className="flex flex-col gap-4">
              <span className=" overflow-hidden">
                <img
                  src="/images/vyom_apartment4.jpeg"
                  alt=""
                  className="xl:w-[40rem] lg:w-[30rem] lg:h-[25rem] zoom-on-hover cursor-pointer"
                />
              </span>
              <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
                <span>Vyom Apartment</span>
                <div className="flex lg:flex-col xl:flex-row  gap-2  sm:text-sm md:text-lg font-normal">
                  <span className="flex gap-2 flex-row">
                    <span className="border-r-2 border-black pr-2">
                      Residential
                    </span>
                    <span className="border-r-2 border-black pr-2">
                      1400 Sq. Ft.
                    </span>
                  </span>
                  <span className=" pr-2">Siliguri</span>
                </div>
              </div>
            </Link>
            <Link to="/client-project" className="flex flex-col gap-4 ">
              <span className="  overflow-hidden">
                <img
                  src="/images/client_project3.jpeg"
                  alt=""
                  className="xl:w-[40rem] lg:w-[30rem] lg:h-[25rem]  zoom-on-hover cursor-pointer"
                />
              </span>
              <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
                <span>Jitendra Goyal</span>
                <div className="flex lg:flex-col xl:flex-row gap-2  sm:text-sm md:text-lg font-normal">
                  <span className="flex gap-2">
                    <span className="border-r-2 border-black pr-2">
                      Jaigaon
                    </span>
                    <span className="border-r-2 border-black pr-2">
                      1200 Sq. Ft.
                    </span>
                  </span>
                  <span className=" pr-2">Siliguri</span>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex sm:flex-col lg:flex-row justify-evenly gap-4 ">
            <Link to="/hotel-project" className="flex flex-col gap-4">
              <span className=" overflow-hidden">
                <img
                  src="/images/hotel_project6.jpeg"
                  alt=""
                  className="xl:w-[40rem] lg:w-[30rem] lg:h-[25rem] zoom-on-hover cursor-pointer"
                />
              </span>
              <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
                <span>Hotel</span>
                <div className="flex lg:flex-col xl:flex-row gap-2  sm:text-sm md:text-lg font-normal">
                  <span className="flex gap-2">
                    <span className="border-r-2 border-black pr-2">Hotel</span>
                    <span className="border-r-2 border-black pr-2">
                      8000 Sq. Ft.
                    </span>
                  </span>
                  <span className=" pr-2">Gangtok Sikkim</span>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </MainPageTemplate>
  );
};

export default ProjectPage;
