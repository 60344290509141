import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    paragraph:
      " I recently worked with the insite stories to revamp my home, and I couldn't be happier with the results. From the initial consultation to the final reveal the insite stories was professional, knowledgeable, and attentive to my needs and preferences. One thing I really appreciated about the insite stories was their ability to listen to my ideas and incorporate them into the design. They also brought their own creativity and expertise to the project, resulting in a space that exceeded my expectations.The finished result is a beautiful and functional space that perfectly reflects my personal style and meets all of my needs. I would highly recommend the insite stories to anyone Looking for inerior decoration of house or office space. ",
    name: "Sanjay Agarwal",
  },
  {
    paragraph:
      " Highly recommended. We used services of the insite stories  last year and glad to say that we are highly satisfied. Pradeep is a young architect who is very professional, an expert and has modern, brilliant and mindful ideas. He listened and understood all our requirements and designed a beautiful house. ",
    name: "Puja Agarwal",
  },
];

const Testimonial = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <div className="w-full flex flex-col items-center font-libra justify-center py-10">
      <div className="xl:w-[90%] lg:w-[95%] sm:w-[90%] flex flex-col h-fit bg-[#e8e9ed] gap-10">
        <div className="flex flex-col gap-4 justify-center items-center md:p-8 sm:p-4 text-black">
          <span className="md:text-xl sm:text-sm font-semibold">
            TESTIMONIALS
          </span>
          <span className="h-[4rem] w-[3px] bg-[#9b9da1]"></span>
          <span className="md:text-2xl sm:text-lg font-bold">
            WHAT OUR CLIENT'S SAY
          </span>
        </div>
        <div className="  w-full   ">
          <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className=" flex w-full justify-center items-center "
              >
                <div className=" justify-center flex  w-full items-center">
                  <div className="md:w-[70%] sm:w-[90%] justify-center text-justify items-center ">
                    <p className="sm:text-xs md:text-base">
                      {testimonial.paragraph}
                    </p>
                    <div className="flex items-center flex-col justify-center mt-4">
                      <img
                        src="/images/dummy.png"
                        alt=""
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="">
                        <p className="md:text-lg sm:text-sm font-semibold">
                          {testimonial.name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
