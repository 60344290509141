import React from "react";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div className="w-full flex flex-col font-libra items-center justify-center py-10">
      <div className="xl:w-[90%] lg:w-[95%] sm:w-[90%] flex flex-col h-fit bg-[#e8e9ed]  gap-10">
        <div className="flex flex-col gap-4 justify-center items-center p-8 text-black">
          <span className="text-xl font-semibold">PROJECTS</span>
          <span className="h-[4rem] w-[3px] bg-[#9b9da1]"></span>
          <span className="text-2xl font-bold">OUR PROJECTS</span>
        </div>
        <div className="p-4 flex sm:gap-4 lg:gap-0 sm:flex-col lg:flex-row h-fit justify-evenly items-center">
          <Link
            to="client-project"
            className="lg:w-[32%] sm:w-full h-fit flex flex-col gap-5"
          >
            <span className=" overflow-hidden">
              <img
                src="/images/client_project3.jpeg"
                alt=""
                className="xl:h-[30rem] lg:h-[25rem] w-full resize-none bg-contain zoom-on-hover"
              />
            </span>
            <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
              <span>Jitendra Goyal</span>
              <div className="flex lg:flex-col xl:flex-row gap-2  sm:text-sm md:text-lg font-normal">
                <span className="flex gap-2">
                  <span className="border-r-2 border-black pr-2">Jaigaon</span>
                  <span className="border-r-2 border-black pr-2">
                    1200 Sq. Ft.
                  </span>
                </span>
                <span className=" pr-2">Siliguri</span>
              </div>
            </div>
          </Link>
          <Link
            to="/hotel-project"
            className="lg:w-[32%] sm:w-full h-fit flex flex-col gap-5"
          >
            <span className=" overflow-hidden">
              <img
                src="/images/hotel_project2.jpeg"
                alt=""
                className="xl:h-[30rem] lg:h-[25rem] w-full zoom-on-hover"
              />
            </span>
            <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
              <span>Hotel</span>
              <div className="flex lg:flex-col xl:flex-row gap-2 sm:text-sm md:text-lg font-normal">
                <span className="flex gap-2">
                  <span className="border-r-2 border-black pr-2">Gangtok</span>
                  <span className="border-r-2 border-black pr-2">
                    8000 Sq. Ft.
                  </span>
                </span>
                <span className=" pr-2"> Sikkim</span>
              </div>
            </div>
          </Link>{" "}
          <Link
            to="/vyom-apartment"
            className="lg:w-[32%] sm:w-full h-fit flex flex-col gap-5"
          >
            <span className=" overflow-hidden">
              <img
                src="/images/vyom_apartment1.jpeg"
                alt=""
                className="xl:h-[30rem] lg:h-[25rem] w-full zoom-on-hover"
              />
            </span>
            <div className="flex flex-col gap-2 lg:text-lg xl:text-xl font-bold">
              <span>Vyom Apartment</span>
              <div className="flex lg:flex-col xl:flex-row  gap-2  sm:text-sm md:text-lg font-normal">
                <span className="flex gap-2 flex-row">
                  <span className="border-r-2 border-black pr-2">
                    Residential
                  </span>
                  <span className="border-r-2 border-black pr-2">
                    1400 Sq. Ft.
                  </span>
                </span>
                <span className=" pr-2">Siliguri</span>
              </div>
            </div>
          </Link>
        </div>
        <Link
          to="/project"
          className="text-lg text-center underline underline-offset-2"
        >
          view all
        </Link>
        <div className="w-full justify-center items-center">
          <img
            src="/images/vyom_apartment5.jpeg"
            alt=""
            className="w-full lg:h-[38rem]"
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
