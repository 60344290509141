import React from "react";
import { FaFacebookSquare, FaInstagram } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className=" border-t-2 font-libra border-black">
      <div className="flex gap-10 sm:flex-col lg:flex-row justify-between items-start sm:p-4 lg:p-16">
        <div className="sm:w-full lg:w-[20%]">
          <img src="images/001.png" alt="" className="w-[8rem] h-[8rem]" />
        </div>
        <div className="flex flex-col sm:w-full lg:w-[20%] gap-4">
          <span className="font-bold text-xl">FIND US</span>
          <span>
            Ground Floor,Shew Bai Bhawan, Shiv Mandir Road , Punjabi Para,
            Siliguri, WB 734001
          </span>
        </div>
        <div className="flex flex-col sm:w-full lg:w-[20%] gap-4">
          <span className="font-bold text-xl">CONTACT US</span>
          <span className="flex flex-col">
            <a href="tel: 8509705946">8509705946</a>
            <a href="mailto:Theinsitestories@gmail.com" target="blank">
              Theinsitestories@gmail.com
            </a>
          </span>
        </div>
        <div className="flex flex-col sm:w-full lg:w-[20%] gap-4">
          <span className="font-bold text-xl">FOLLOW US</span>
          <span className="flex gap-4">
            <a
              href="https://www.instagram.com/theinsitestories?igsh=MXBxYmY2aWRpbjlwcw=="
              target="blank"
            >
              <span>
                <FaInstagram className="text-2xl" />
              </span>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090021741084&mibextid=ZbWKwL"
              target="blank"
            >
              <span>
                <FaFacebookSquare className="text-2xl" />
              </span>
            </a>
            <a
              href="https://youtube.com/@thein-sitestories1651?si=zEYwrxSr1iojN1zn"
              target="blank"
            >
              {" "}
              <span>
                <IoLogoYoutube className="text-2xl" />
              </span>
            </a>
          </span>
        </div>
      </div>
      <div className="flex md:flex-row md:h-fit sm:h-[9rem] sm:flex-col sm:text-xs md:text-lg lg:text-lg sm:justify-start items-center md:justify-between bg-gray-200 border-t border-white  sm:p-2 md:p-6 ">
        <div className="  text-black    flex flex-col gap-2  text-center ">
          <span> Copyright@ The In-site Stories 2023</span>
        </div>
        <div className="  text-black      flex gap-2  ">
          <span>Developed By:</span>
          <Link to="https://rebootmarketing.in/" target="_blank">
            Reboot Marketing Pvt. Ltd.
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
