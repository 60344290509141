import React, { useState } from "react";

import { FaFacebookSquare } from "react-icons/fa";
import { SiInstagram } from "react-icons/si";
import { IoLogoYoutube } from "react-icons/io";
import Footer from "../component/Footer";
import { FaArrowRight } from "react-icons/fa";
import MainPageTemplate from "../element/MainPageTemplate";
import emailjs from "@emailjs/browser";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",

    phone: "",

    message: "",

    email: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, phone, email, message } = formData;

    // Check if all required fields are filled
    if (!name || !phone || !email || !message) {
      alert("Please fill in all required fields.");
      return;
    }

    emailjs
      .sendForm(
        "service_fmt0s31",
        "template_8yxqbyu",
        e.target,
        "ByOjW7EDMosGqclRt"
      )
      .then(
        () => {
          alert("We are connecting you.");
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Clear the form data after submission
    setFormData({
      name: "",

      phone: "",

      message: "",

      email: "",
    });
  };
  const textColor = "md:text-black sm:text-gray-500";

  return (
    <MainPageTemplate textColor={textColor}>
      <div className=" relative">
        <div className="mt-[7rem] font-libra ">
          <div className="flex items-center gap-2 font-normal z-50 sm:text-[3rem] md:text-[5rem] lg:text-[8rem] xl:text-[12rem] xxl:text-[16rem] justify-center">
            <span>Get In</span>
            <span className="contact-text z-10">Touch</span>
          </div>
          <div className="relative sm:top-[-2rem] lg:top-[-5rem] xl:top-[-10rem] left-0 w-full">
            <img
              src="images/contact-img1.png"
              alt=""
              className="w-full h-fit"
              style={{ zIndex: -1 }}
            />
            <div className="sm:relative xl:absolute sm:p-4 lg:p-0 inset-0 flex text-4xl flex-col sm:w-full lg:w-[40%] justify-center gap-20 sm:left-[1rem] sm:top-[-10rem] xl:top-[20rem] xl:left-[7rem] lg:left-[5rem] lg:top-[-40rem] xxl:left-[12rem] md:left-[2rem] md:top-[-20rem]">
              <div className="text-3xl font-semibold">
                Feel free to write us
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-20 w-[90%]"
              >
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  className="border-b-4 border-black p-4"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  className="border-b-4 border-black p-4"
                />
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Mobile"
                  className="border-b-4 border-black p-4"
                />
                <textarea
                  placeholder="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="border-b-4 border-black p-4 min-h-20 max-h-20"
                ></textarea>
                <button
                  type="submit"
                  className="w-[8rem] h-[3rem] flex justify-center items-center text-xl rounded-md text-white  bg-slate-600"
                >
                  submit
                </button>
              </form>
              <div className="flex flex-col gap-4 text-3xl">
                <span className="font-semibold text-gray-600">
                  Corporate House :
                </span>
                <span>
                  Ground Floor,Shew Bai Bhawan, Shiv Mandir Road , Punjabi Para,
                  Siliguri, WB 734001
                </span>
              </div>
              <div className="flex flex-col gap-2 text-3xl">
                <a href="tel: 8509705946">8509705946</a>

                <a href="mailto:Theinsitestories@gmail.com" target="blank">
                  Theinsitestories@gmail.com
                </a>
              </div>
              <div>
                <div className="flex gap-6  sm:text-gray-500 p-4 text-6xl">
                  <a
                    href="https://www.instagram.com/theinsitestories?igsh=MXBxYmY2aWRpbjlwcw=="
                    target="blank"
                  >
                    <span>
                      <SiInstagram />
                    </span>
                  </a>
                  <a
                    href="https://www.facebook.com/profile.php?id=100090021741084&mibextid=ZbWKwL"
                    target="blank"
                  >
                    <span>
                      <FaFacebookSquare />
                    </span>
                  </a>
                  <a
                    href="https://youtube.com/@thein-sitestories1651?si=zEYwrxSr1iojN1zn"
                    target="blank"
                  >
                    <span>
                      <IoLogoYoutube />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative justify-center text-white items-center w-full bg-black sm:h-fit md:h-[20rem] lg:h-[40rem]">
        <div className="p-8 flex justify-between text-white ">
          <span className="lg:w-[40%]  sm:text-2xl lg:text-4xl">
            Ground floor,shew bai bhawan ,shiv mandir road , punjabi para ,
            Siliguri,734001
          </span>
          <div className="flex gap-6  sm:text-white p-4 sm:text-2xl lg:text-6xl">
            <a
              href="https://www.instagram.com/theinsitestories?igsh=MXBxYmY2aWRpbjlwcw=="
              target="blank"
            >
              <span>
                <SiInstagram />
              </span>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100090021741084&mibextid=ZbWKwL"
              target="blank"
            >
              <span>
                <FaFacebookSquare />
              </span>
            </a>
            <a
              href="https://youtube.com/@thein-sitestories1651?si=zEYwrxSr1iojN1zn"
              target="blank"
            >
              <span>
                <IoLogoYoutube />
              </span>
            </a>
          </div>
        </div>
        <div className="w-full justify-center flex sm:text-[4rem] lg:text-[8rem] text-white text-center items-center">
          Let's talk
          <FaArrowRight className="lg:text-7xl sm:text-4xl" />
        </div>
      </div>
      <div className="relative ">
        <Footer />
      </div>
    </MainPageTemplate>
  );
};

export default ContactPage;
