import React, { useState } from "react";

const Services = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full flex flex-col font-libra items-center justify-center py-10">
      <div className="w-[90%] flex flex-col h-fit   gap-10">
        <div className="flex flex-col gap-4 justify-center items-center p-8 text-black">
          <span className="text-xl font-semibold">SERVICES</span>
          <span className="h-[4rem] w-[3px] bg-[#9b9da1]"></span>
          <span className="text-2xl font-bold">OUR EXPERTISE</span>
        </div>
        <div className="flex sm:flex-col lg:flex-row justify-evenly gap-4 items-center">
          <div className="flex flex-col gap-4">
            <span className=" overflow-hidden">
              <img
                src="/images/View02 (1).jpg"
                alt=""
                className="xl:w-[35rem] lg:w-[30rem] lg:h-[25rem] zoom-on-hover cursor-pointer"
                onClick={() => openModal("/images/View02 (1).jpg")}
              />
            </span>
            <span className="text-xl font-semibold">Interior Design</span>
          </div>
          <div className="flex flex-col gap-4 ">
            <span className="  overflow-hidden">
              <img
                src="/images/Homepage_Architect_Pictures.jpeg"
                alt=""
                className="xl:w-[35rem] lg:w-[30rem] lg:h-[25rem]  zoom-on-hover cursor-pointer"
                onClick={() =>
                  openModal("/images/Homepage_Architect_Pictures.jpeg")
                }
              />
            </span>
            <span className="text-xl font-semibold">Architectural Design</span>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="fixed top-0 left-0 w-full z-40 h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="relative  p-4 rounded-lg">
            <button
              className="absolute top-2 z-50 right-2 bg-red-500 text-white rounded-full w-8 h-8 flex items-center justify-center"
              onClick={closeModal}
            >
              X
            </button>
            <img
              src={selectedImage}
              alt=""
              className="lg:w-[50rem] lg:h-[30rem] sm:h-fit sm:w-full"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
