import React, { useEffect, useMemo, useRef, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { BiMenuAltRight } from "react-icons/bi";
import { SiInstagram } from "react-icons/si";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { Link } from "react-router-dom";
const Header = ({ textColor }) => {
  const NavElement = useMemo(
    () => [
      { name: "HOME", link: "/" },
      { name: "ABOUT", link: "/about" },
      { name: "PROJECTS", link: "/project" },
      { name: "CONTACT", link: "/contact" },
    ],
    []
  );

  const closeModal = () => {
    setOpenMenu(false);
  };
  const [openMenu, setOpenMenu] = useState(false);

  const [scrolled, setScrolled] = useState(false);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={` fixed w-full duration-300 lg:left-0 lg:right-0 lg:transition-all  md:h-28 sm:h-fit flex flex-row justify-around items-center sm:flex-col md:flex-row  z-30 ${
        scrolled ? "bg-white " : "bg-transparent "
      }`}
    >
      <div className="w-[40%] flex  sm:justify-between sm:p-[4%] sm:items-center sm:w-full sm:h-16  ">
        <img
          src="images/001.png"
          alt="logo"
          className="sm:w-[3rem] sm:h-[3rem] md:w-[6rem] md:h-[6rem]"
        />

        <button
          onClick={() => setOpenMenu(!openMenu)}
          className="md:hidden sm:block cursor-pointer"
        >
          {openMenu ? (
            <RxCross2 className=" z-[100] cursor-pointer text-white text-2xl inset-1" />
          ) : (
            <BiMenuAltRight className="text-3xl text-black" />
          )}
        </button>
      </div>
      <div
        ref={menuRef}
        className={`  md:w-[60%] md:flex-row md:flex md:justify-evenly md:mr-20  md:gap-10 sm:top-[100%] sm:z-[99]  sm:flex-col sm:w-full ${
          openMenu ? "block" : "hidden md:flex"
        }`}
      >
        <button className="w-[10rem]">
          <img
            src="images/001.jpg"
            alt=""
            className="md:hidden w-[8rem] h-[8rem] pl-4 bg-no-repeat"
          />
        </button>
        <button
          className="absolute top-2   z-50 right-4 md:hidden text-4xl p-20 text-white rounded-full w-8 h-8 flex items-center "
          onClick={closeModal}
        >
          X
        </button>
        {NavElement.map((link) => (
          <div
            key={link.name}
            className={`flex sm:justify-start font-libra sm:p-4 md:p-0 lg:p-4  md:gap-8 lg:gap-8 md:justify-end sm:text-2xl md:text-sm xl:text-base font-semibold ${
              scrolled
                ? "md:text-black sm:text-gray-500"
                : textColor || "text-black"
            }`}
          >
            <Link to={link.link}>{link.name}</Link>
          </div>
        ))}
        <div className="sm:flex sm:gap-6 md:hidden sm:text-gray-500 p-4 text-4xl">
          <a
            href="https://www.instagram.com/theinsitestories?igsh=MXBxYmY2aWRpbjlwcw=="
            target="blank"
          >
            <span>
              <SiInstagram />
            </span>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100090021741084&mibextid=ZbWKwL"
            target="blank"
          >
            <span>
              <FaFacebookSquare />
            </span>
          </a>
          <a
            href="https://youtube.com/@thein-sitestories1651?si=zEYwrxSr1iojN1zn"
            target="blank"
          >
            <span>
              <IoLogoYoutube />
            </span>
          </a>
        </div>
      </div>

      {openMenu && (
        <div
          className="fixed inset-0 bg-black  z-50"
          onClick={() => setOpenMenu(false)}
        ></div>
      )}
    </div>
  );
};

export default Header;
