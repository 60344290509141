import React from "react";

const About = () => {
  return (
    <div className="w-full flex justify-center font-libra py-10">
      <div className="xl:w-[90%] lg:w-[95%] sm:w-[90%] flex flex-col h-fit bg-black sm:p-4 md:p-10 gap-10">
        <div className="flex flex-col gap-4 justify-center items-center text-white">
          <span className="text-xl font-semibold">About</span>
          <span className="h-[4rem] w-[3px] bg-white"></span>
          <span className="text-2xl font-bold">WHY CHOOSE US</span>
        </div>
        <div className="flex sm:flex-col lg:flex-row justify-evenly w-full gap-10  items-center">
          <div className="flex flex-row justify-evenly sm:w-full ">
            <div className="flex w-[20%] flex-col gap-3 text-center items-center text-white">
              <span>
                <img
                  src="/images/download.jpg"
                  alt=""
                  className="md:w-[5rem] md:h-[5rem] sm:w-[3rem] sm:h-[3rem]"
                />
              </span>
              <span className="lg:text-xl sm:text-xs md:text-lg font-semibold">
                3D VISUALIZATION
              </span>
            </div>
            <div className="flex w-[20%] flex-col gap-3 text-center items-center text-white">
              <span>
                <img
                  src="/images/download2.png"
                  alt=""
                  className="md:w-[5rem] md:h-[5rem] sm:w-[3rem] sm:h-[3rem]"
                />
              </span>
              <span className="lg:text-xl sm:text-xs md:text-lg font-semibold">
                CUSTOMISED FURNITURE
              </span>
            </div>
          </div>{" "}
          <div className="flex flex-row justify-evenly items-center sm:w-full ">
            <div className="flex w-[20%] flex-col gap-3 text-center items-center text-white">
              <span>
                <img
                  src="/images/download3.png"
                  alt=""
                  className="md:w-[5rem] md:h-[5rem] sm:w-[3rem] sm:h-[3rem]"
                />
              </span>
              <span className="lg:text-xl sm:text-xs md:text-lg font-semibold">
                PROFESSIONAL PROJECT HANDLING
              </span>
            </div>{" "}
            <div className="flex w-[20%] flex-col gap-3 items-center text-center text-white">
              <span>
                <img
                  src="/images/download4.png"
                  alt=""
                  className="md:w-[5rem] md:h-[5rem] sm:w-[3rem] sm:h-[3rem]"
                />
              </span>
              <span className="lg:text-xl sm:text-xs md:text-lg font-semibold">
                ON-TIME PROJECT COMPLETION
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
