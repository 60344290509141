import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home";

import Aboutpage from "./pages/Aboutpage";
import ProjectPage from "./pages/ProjectPage";
import ContactPage from "./pages/ContactPage";

import ClientProject from "./pages/ClientProject";
import HotelProject from "./pages/HotelProject";
import VyomApartment from "./pages/VyomApartment";

function App() {
  return (
    <div className=" ">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about" element={<Aboutpage />}></Route>
          <Route path="/project" element={<ProjectPage />}></Route>
          <Route path="/contact" element={<ContactPage />}></Route>
          <Route path="/client-project" element={<ClientProject />}></Route>
          <Route path="/hotel-project" element={<HotelProject />}></Route>
          <Route path="/vyom-apartment" element={<VyomApartment />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
