import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Banner() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    dots: true,
    fade: true,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "20px",
          textAlign: "center",
          width: "100%",
        }}
      >
        <ul style={{ margin: "0" }}> {dots} </ul>
      </div>
    ),
  };
  const slides = [
    {
      imageSrc: "/images/View02 (1).jpg",
      title: "INTERIOR DESIGN STUDIO",
    },
    {
      imageSrc: "/images/hotel_project3.jpeg",
      title: "INTERIOR DESIGN STUDIO",
    },
    {
      imageSrc: "/images/Angle_2 (1).jpg",
      title: "INTERIOR DESIGN STUDIO",
    },
    {
      imageSrc: "/images/client_project5.jpeg",
      title: "INTERIOR DESIGN STUDIO",
    },
  ];

  return (
    <div className="relative">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div className="carousel-item relative" key={index}>
            <div className="w-full relative">
              <img
                src={slide.imageSrc}
                alt=""
                className="w-full resize sm:h-[500px] md:h-[700px]"
              />
              <div className="absolute inset-0 flex sm:flex-col md:flex-row items-center sm:mb-8 lg:mb-0 justify-end gap-10  sm:p-4 lg:p-8 font-[Montserrat] font-bold text-white  w-full">
                <div className="md:w-[35%] sm:w-full ">
                  <div className=" flex flex-col font-libra gap-5 ">
                    <div className="w-[5rem] h-[5px] bg-white"></div>
                    <p className="sm:text-4xl  md:text-4xl xl:text-6xl">
                      {slide.title}
                    </p>
                    <p className=" sm:text-2xl  lg:text-3xl mt-4">
                      BUILDING BEAUTIFUL SPACES
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Banner;
