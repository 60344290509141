import React, { useState } from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import Footer from "../component/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight, FaTimes } from "react-icons/fa";
const ClientProject = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const CustomArrow = ({ onClick, direction }) => (
    <div
      className={`absolute bottom-4 ${
        direction === "prev" ? "right-20" : "right-10"
      } flex items-center justify-center mb-4 cursor-pointer`}
      onClick={onClick}
    >
      {direction === "prev" ? (
        <FaChevronLeft className="z-10 text-2xl" />
      ) : (
        <FaChevronRight className="text-2xl" />
      )}
    </div>
  );

  function handleSlideChange(currentSlide, nextSlide) {}

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    fade: true,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    beforeChange: (current, next) => handleSlideChange(current, next),
  };

  const slides = [
    {
      imgsrc: "/images/client_project1.jpeg",
    },
    {
      imgsrc: "/images/client_project2.jpeg",
    },
    {
      imgsrc: "/images/client_project5.jpeg",
    },
    {
      imgsrc: "/images/client_project4.jpeg",
    },
  ];

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateImage = (direction) => {
    if (direction === "prev") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === 0 ? slides.length - 1 : prevIndex - 1
      );
    } else if (direction === "next") {
      setSelectedImageIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }
  };
  const textColor = "md:text-black sm:text-gray-500";

  return (
    <MainPageTemplate textColor={textColor}>
      <div className="xl:p-16 sm:p-4 md:p-8 mt-[5rem]">
        <div className="flex h-fit sm:flex-col lg:flex-row justify-center lg:items-end ">
          <div className="lg:w-[40%] sm:w-full sm:text-sm md:text-base xl:text-lg flex flex-col gap-5 pb-7">
            <span className="xl:text-5xl sm:text-2xl md:text-3xl  font-semibold">
              JITENDRA GOYAL
            </span>
            <span className="flex flex-row gap-5">
              <span className="w-[40%] flex justify-between">
                <span className="text-gray-600">Area</span>
                <span>:</span>
              </span>
              <span className="w-[50%]">Jaigaon </span>
            </span>
            <span className="flex flex-row gap-5">
              <span className="w-[40%] flex justify-between">
                <span className="text-gray-600">Status</span>
                <span>:</span>
              </span>
              <span className="w-[50%]">Completed</span>
            </span>
            <span className="flex flex-row gap-5">
              <span className="w-[40%] flex justify-between">
                <span className="text-gray-600">City</span>
                <span>:</span>
              </span>
              <span className="w-[50%]">Alipurduar </span>
            </span>
            <span className="flex flex-row gap-5">
              <span className="w-[40%] flex justify-between">
                <span className="text-gray-600">Interior By</span>
                <span>:</span>
              </span>
              <span className="w-[50%]">Pradeep Bansal</span>
            </span>
            <span className="flex flex-row gap-5">
              <span className="w-[40%] flex justify-between">
                <span className="text-gray-600">Completed Year</span>
                <span>:</span>
              </span>
              <span className="w-[50%]">2022</span>
            </span>
          </div>
          <div className=" sm:w-full lg:w-[60%]">
            <span className=" w-full h-fit overflow-hidden">
              <img
                src="/images/client_project1.jpeg"
                alt=""
                className="xl:h-[33rem] lg:h-[25rem] w-full"
              />
            </span>
          </div>
        </div>
        <div className="flex w-full items-center justify-center">
          <div className="sm:w-full lg:w-[80%] h-fit mt-16">
            <Slider {...settings}>
              {slides.map((slide, index) => (
                <span
                  className="w-full h-fit overflow-hidden"
                  key={index}
                  onClick={() => openModal(index)}
                >
                  <img
                    src={slide.imgsrc}
                    alt=""
                    className="xl:h-[40rem] md:h-[25rem] sm:h-[15rem] w-full cursor-pointer"
                  />
                </span>
              ))}
            </Slider>
          </div>
        </div>

        {showModal && (
          <div className="fixed top-0 left-0 w-full h-full z-[100] flex justify-center items-center bg-black bg-opacity-80">
            <div className="relative w-full flex justify-center items-center">
              <img
                src={slides[selectedImageIndex].imgsrc}
                alt=""
                className="w-[60%]"
              />
              <div className="absolute w-full flex justify-between p-4">
                <FaChevronLeft
                  className="text-white text-3xl cursor-pointer"
                  onClick={() => navigateImage("prev")}
                />
                <FaChevronRight
                  className="text-white text-3xl cursor-pointer"
                  onClick={() => navigateImage("next")}
                />
              </div>
            </div>
            <div className="top-0 absolute right-0 p-16">
              <FaTimes
                className="text-white text-3xl cursor-pointer"
                onClick={closeModal}
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </MainPageTemplate>
  );
};

export default ClientProject;
