import React, { useState, useEffect } from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import Footer from "../component/Footer";
import "../styles/animations.css"; // Import CSS file containing animation styles

const Aboutpage = () => {
  // State to track visibility of each section
  const [lastVisibleIndex, setLastVisibleIndex] = useState(-1);

  useEffect(() => {
    const handleScroll = () => {
      const sections = ["passionate", "resourceful", "subservient", "careful"];

      const viewportHeight = window.innerHeight;

      let lastVisible = -1;
      for (let i = 0; i < sections.length; i++) {
        const section = document.getElementById(sections[i]);
        if (section) {
          const sectionOffset = section.offsetTop;
          const scrollPosition = window.scrollY + viewportHeight;
          if (scrollPosition > sectionOffset) {
            lastVisible = i;
          }
        }
      }

      setLastVisibleIndex(lastVisible);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const textColor = "md:text-black sm:text-gray-500";

  return (
    <MainPageTemplate textColor={textColor}>
      <div>
        <div className="h-[600px] w-full font-libra flex items-center bg-[#eaeaea]">
          <div className="w-full flex ">
            <div className="flex flex-row text-5xl sm:w-full lg:w-[50%] items-center justify-center">
              <div className="flex flex-col ">
                <span>We are</span>
                <span className="  text-gray-700">The In Site Stories</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row sm:flex-col relative sm:p-4 md:p-0 h-fit top-[-13rem] ">
          <span className="sm:w-full lg:w-[50%] flex items-center justify-center">
            <span>
              <img
                src="images/PradipAgarwal.jpeg"
                alt=""
                className="w-[26rem] h-[28rem]"
              />
            </span>
          </span>
          <div className="sm:w-full lg:w-[60%] text-justify xl:text-lg sm:text-xl lg:text-base font-semibold items-end justify-end p-4">
            There’s a story behind every site, we just infuse life into these
            stories! We are often asked, why should we get our spaces designed?
            And we reply with a story that lights up their faces. A space
            remains incomplete without the right furniture, furnishings,
            decorations and lights. And not every person knows how to create a
            balance between interior design elements. It is an art that requires
            study, practice and a little bit of magic! Your space needs just
            that – some magic by The In-site Stories
          </div>
        </div>
        {/* Render each section */}
        <div>
          <div
            className={`flex w-full h-fit justify-end items-end ${
              lastVisibleIndex >= 0 ? "animate-slide-in" : ""
            }`}
            id="passionate"
          >
            <div className="flex flex-col h-full sm:top-[-10rem] lg:top-[-25rem] sm:w-full lg:w-[80%] sm:relative bg-white sm:p-4 lg:p-16">
              <span className="text-4xl font-medium">Pradeep Bansal</span>
              <div className="p-4 flex flex-col gap-5">
                {" "}
                <span className="text-xl">WE ARE</span>
                <span className="h-[3rem] w-[2px] bg-gray-500"></span>
              </div>

              <div className="flex flex-col gap-10">
                <span className="text-3xl font-semibold">Passionate</span>

                <span className="text-xl">
                  We want to give the best answer to the question we are asked.
                  We listen to all wishes. And listen between the lines. We
                  think along and beyond. So that we even know how to fulfill
                  underlying wishes.
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex w-full h-fit justify-end items-end ${
              lastVisibleIndex >= 1 ? "animate-slide-in" : ""
            }`}
            id="resourceful"
          >
            <div className="flex flex-col h-full sm:top-[-10rem] lg:top-[-25rem] sm:w-full lg:w-[80%] sm:relative bg-white sm:p-4 lg:p-16">
              <div className="flex flex-col gap-10">
                <span className="text-3xl font-semibold">Resourceful</span>

                <span className="text-xl">
                  We see potential in a building or place. And we don't keep
                  that to ourselves. We start a project full of dedication and
                  want to get everything out of it. We create opportunities,
                  show guts. And deal with the regulations smartly. This is how
                  we continue to surprise, design after design.
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex w-full h-fit justify-end items-end ${
              lastVisibleIndex >= 2 ? "animate-slide-in" : ""
            }`}
            id="subservient"
          >
            <div className="flex flex-col h-full sm:top-[-10rem] lg:top-[-25rem] sm:w-full lg:w-[80%] sm:relative bg-white sm:p-4 lg:p-16">
              <div className="flex flex-col gap-10">
                <span className="text-3xl font-semibold">Subservient</span>

                <span className="text-xl">
                  To our client, to the users, to the place. A design is close
                  to our hearts, but our will is certainly not law. Together we
                  come up with a unique plan. We deliver customization. This is
                  evident from the versatility in our work.
                </span>
              </div>
            </div>
          </div>
          <div
            className={`flex w-full h-fit justify-end items-end ${
              lastVisibleIndex >= 3 ? "animate-slide-in" : ""
            }`}
            id="careful"
          >
            <div className="flex flex-col h-full sm:top-[-10rem] lg:top-[-25rem] sm:w-full lg:w-[80%] sm:relative bg-white sm:p-4 lg:p-16">
              <div className="flex flex-col gap-10">
                <span className="text-3xl font-semibold">Careful</span>
                <span className="text-xl">
                  We pride ourselves on our imagination. On every scale level,
                  down to the last detail. We look for balance. Between the
                  interior, exterior, the place. We keep designing until
                  everything comes together, until there is a design with the
                  desired functionality and beauty. A design that feels good.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full   flex flex-col sm:p-4 lg:p-16 text-white bg-black">
          <div className="flex flex-col gap-5 sm:w-full lg:w-[50%]">
            <span className="text-5xl"></span>
            <span className="text-3xl">
              We are an ambitious Interior firm that wants to answer all our
              client's questions in every design, resulting in an inspiring
              living environment.
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </MainPageTemplate>
  );
};

export default Aboutpage;
