import React from "react";
import MainPageTemplate from "../element/MainPageTemplate";
import Banner from "../component/Banner";
import About from "../component/About";
import Projects from "../component/Projects";
import Services from "../component/Services";
import Testimonial from "../component/Testimonial";
import Footer from "../component/Footer";

const Home = () => {
  const textColor = "md:text-white sm:text-gray-500";
  return (
    <div>
      <MainPageTemplate textColor={textColor}>
        <Banner />
        <About />
        <Projects />
        <Services />
        <Testimonial />
        <Footer />
      </MainPageTemplate>
    </div>
  );
};

export default Home;
