import React, { useEffect } from "react";
import Header from "../component/Header";

const MainPageTemplate = ({ textColor, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="flex w-full h-full flex-col overflow-y-auto overflow-x-hidden ">
        <div className="">
          <Header textColor={textColor} />
        </div>
        <div className="font-libra">{children}</div>
      </div>
    </div>
  );
};

export default MainPageTemplate;
